import { createI18n } from "vue-i18n";

export const i18n = createI18n({
  legacy: false,
  locale: "ko",
  messages: {
    ko: {
      web_category: {
        webtoon: "웹툰",
        novel: "소설",
      },
      toon_category: {
        all: "전체",
        serialize: "연재",
        genre: "장르",
        ranking: "랭킹",
        original: "오리지널",
      },
      toon_category_text: {
        weekly_webtoon: "주간 웹툰",
        weekly_webtoon_subtitle: "매주 업데이트되는 웹툰",
        ranking_webtoon: "인기 웹툰",
        ranking_webtoon_subtitle:
          "많은 사랑을 받고 있는 인기 웹툰을 모아봤어요",
        new_webtoon: "신작 웹툰",
        new_webtoon_subtitle: "새로운 작품을 만나보세요",
        original_webtoon: "독점 웹툰",
        original_webtoon_subtitle: "독점 웹툰을 무료로 만나보세요",
        genre_webtoon: "장르별 웹툰",
      },
      login: "로그인",
      forgot_password: "비밀번호 초기화",
      sign_up: "회원가입",
      confirm: "확인",
      cancel: "취소",
      e_mail: "이메일",
      password: "비밀번호",
      password_confirm: "비밀번호 확인",
      verify_code: "인증코드",
      total: "총",
      episode: "화",
      sort_latest: "최신순",
      sort_oldest: "오래된순",
      watch_first_episode: "첫화보기",
      free: "무료",
    },
    en: {
      web_category: {
        webtoon: "WEBTOON",
        novel: "NOVEL",
      },
      toon_category: {
        all: "ALL",
        serialize: "SERIALIZED",
        genre: "GENRE",
        ranking: "RANKING",
        original: "ORIGINAL",
      },
      toon_category_text: {
        weekly_webtton: "Weekly Webtoon",
        weekly_webtoon_subtitle: "Webtoon updated every week",
        ranking_webtoon: "Popular Webtoon",
        ranking_webtoon_subtitle:
          "Popular webtoons that have received a lot of love",
        new_webtoon: "New Webtoon",
        new_webtoon_subtitle: "Meet new works",
        original_webtoon: "Original Webtoon",
        original_webtoon_subtitle: "Meet original webtoons for free",
      },
      login: "LOGIN",
      forgot_password: "FORGOT PASSWORD",
      sign_up: "Join Membership",
      confirm: "Confirm",
      cancel: "Cancel",
      e_mail: "E-mail",
      password: "Password",
      password_confirm: "Password Confirm",
      verify_code: "Verification code",
      total: "A total of",
      episode: "Episode",
      sort_latest: "Latest",
      sort_oldest: "Oldest",
      watch_first_episode: "WATCH THE FIRST EPISODE",
      free: "FREE",
    },
    zh: {},
    ja: {},
  },
});
