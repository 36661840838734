import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import { i18n } from "./assets/js/i18n";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import vue3GoogleLogin from "vue3-google-login";
import vue3StarRatings from "vue3-star-ratings";

require("./assets/style/css/style.css");

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);
app.config.globalProperties.$axios = axios;

app.component("vue3-star-ratings", vue3StarRatings);

app
  .use(pinia)
  .use(router)
  .use(i18n)
  .use(vue3GoogleLogin, {
    clientId:
      "942968685185-5dtoiurbavm00lnul801g3rhleafvefs.apps.googleusercontent.com",
  })
  .mount("#app");
