import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "ViewLogin",
    component: () => import("@/view/ViewLogin.vue"),
  },
  {
    path: "/webtoon",
    name: "ViewWebToon",
    component: () => import("@/view/ViewWebToon.vue"),
  },
  {
    path: "/webtoon/:id",
    name: "ViewEpisodeList",
    component: () => import("@/view/ViewEpisodeList.vue"),
  },
  {
    path: "/webtoon/:id/episode/:episode_id",
    name: "ViewEpisode",
    component: () => import("@/view/ViewEpisode.vue"),
  },
  {
    path: "/community",
    name: "ViewCommunity",
    component: () => import("@/view/ViewCommunity.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
